<template>
  <div class="section" id="Welcome">

    <div class="body-content">

        <SectionTitle text="Welkom"/>

        <p>In deze logopedische praktijk in Hasselt kan men terecht voor het behandelen van een:</p>

        <div class="links">
            
            <Treatment text="Leerstoornis" @click="this.$emit('leerstoornissen');"/>
            <Treatment text="Taalontwikkelingsstoornis" @click="this.$emit('taal');"/>
            <Treatment text="Articulatiestoornis" @click="this.$emit('artic');"/>
            <Treatment text="Myofunctionele Stoornis (OMFT)" @click="this.$emit('OMFT');"/>
            <Treatment text="Afasie" @click="this.$emit('afasie');"/>
            <Treatment text="Dysartrie" @click="this.$emit('dysartrie');"/>

        </div>

        <p>Ik werk voornamelijk met zelfontworpen bundeltjes voor een betere begeleiding, gebaseerd op mijn ervaringen. Die helpen om op een gestructureerde manier te werk te gaan en brengen het evolutieproces mooi mee in kaart.</p>

    </div>

    <div id="shapes">
        <div class="shape foreground">
            <img src="../assets/Foreground.svg">
        </div>
        <div class="shape middleground">
            <img src="../assets/Lara.png">
        </div>
        <div class="shape background">
            <img src="../assets/Background.svg" alt="">
        </div>
    </div>

  </div>
</template>

<script>
import SectionTitle from './blocks/SectionTitle.vue'
import Treatment from './blocks/Treatment.vue'

import { showLeer } from './Behandelingen.vue'

export default {
    components: {
        SectionTitle,
        Treatment,
        showLeer
  }
}
</script>

<style>
#Welcome {
  scroll-padding-top: -100px;
}

.shape{
    position: absolute;
}

.foreground{
    right: 0;
    bottom: -6px;

    z-index: 3;
}

.middleground{
    right: 261px;
    bottom: 191px;

    z-index: 2;
}

.background{
    right: 0;
    bottom: -393px;

    z-index: 1;
}

#Welcome .body-content{
    padding-top: 56px;
    padding-bottom: 160px;

    position: relative;
    z-index: 4;
}

#Welcome p{
    width: 657px;;
}

.links{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    max-width: 800px;
    margin: 40px 0 56px 0;
}

@media screen and (max-width: 600px){
    #Welcome p{
        width: auto;
    }

    #Welcome .body-content{
    padding-top: 40px;
    padding-bottom: 120px;
}

    #shapes{
        height: 261px;

        display: none;
    }

    .shape{
        position: relative;
    }

    .foreground{
        height: 644px;

        right: -86px;
        top: -182px;
    }
}

</style>